import Icon from '../components/Icon';

const Gallery = Icon(
  'gallery',
  <>
    <path d="M12.217 6.889a1.333 1.333 0 1 1-2.667 0 1.333 1.333 0 0 1 2.667 0" />
    <path
      fillRule="evenodd"
      d="M8 1.25A2.75 2.75 0 0 0 5.25 4v12A2.75 2.75 0 0 0 8 18.75h12A2.75 2.75 0 0 0 22.75 16V4A2.75 2.75 0 0 0 20 1.25zM6.75 4c0-.69.56-1.25 1.25-1.25h12c.69 0 1.25.56 1.25 1.25v6.842l-1.588-1.585a2.25 2.25 0 0 0-3.18 0l-3.797 3.793-1.573-1.563a2.25 2.25 0 0 0-3.177.005L6.75 12.677zm0 10.798V16c0 .69.56 1.25 1.25 1.25h12c.69 0 1.25-.56 1.25-1.25v-3.039l-2.648-2.642a.75.75 0 0 0-1.06 0l-4.326 4.32a.75.75 0 0 1-1.058.001l-2.103-2.089a.75.75 0 0 0-1.06.002z"
      clipRule="evenodd"
    />
    <path d="M2 5.25a.75.75 0 0 1 .75.75v14A1.25 1.25 0 0 0 4 21.25h14a.75.75 0 0 1 0 1.5H4A2.75 2.75 0 0 1 1.25 20V6A.75.75 0 0 1 2 5.25" />
  </>
);

export default Gallery;
