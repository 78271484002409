'use client';

import { createContext, useContext, useState } from 'react';

type LightboxContextType = {
  isLightboxOpen: boolean;
  openLightbox: (e: any, lightboxStartingIndex: number) => void;
  closeLightbox: () => void;
  lightboxStartingIndex: number;
};

const LightboxContext = createContext<LightboxContextType>({
  isLightboxOpen: false,
  openLightbox: () => {},
  closeLightbox: () => {},
  lightboxStartingIndex: 0
});

export const LightboxProvider = ({ children }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxStartingIndex, setLightboxStartingIndex] = useState<number>(0);

  const openLightbox = (e: any, lightboxStartingIndex: number) => {
    setLightboxOpen(true);
    setLightboxStartingIndex(lightboxStartingIndex);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setLightboxStartingIndex(0);
  };

  return (
    <LightboxContext.Provider value={{ isLightboxOpen: lightboxOpen, openLightbox, closeLightbox, lightboxStartingIndex }}>
      {children}
    </LightboxContext.Provider>
  );
};

export const useLightbox = () => useContext(LightboxContext);
