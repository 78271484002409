import { Image } from '@alltrails/core';
import { getPhotoUrl } from '@alltrails/shared/utils/requests/photoRequests';
import { apiKey } from '@alltrails/networking';
import { PhotoSize } from '@alltrails/shared/types/photos';
import { BREAKPOINT_MD_SIZE, BREAKPOINT_MD_MARGIN, BREAKPOINT_LG_SIZE, BREAKPOINT_LG_MARGIN, SPACE_600 } from '@alltrails/denali/tokens';
import styles from './PhotoHeaderGrid.module.scss';
import { PhotoHash } from '@/types/shared';

// We are using flexbox to create the grid, where the primary image has flex: 5 and the secondary images have flex: 3
const getImageSize = (screenSize: number, secondaryImage: boolean) => (secondaryImage ? screenSize * (3 / 8) : screenSize * (5 / 8));
const secondarySizes = `(max-width: ${BREAKPOINT_MD_SIZE}) ${getImageSize(BREAKPOINT_MD_SIZE - BREAKPOINT_MD_MARGIN * 2, true)}px, ${getImageSize((BREAKPOINT_LG_SIZE - BREAKPOINT_LG_MARGIN * 2) / 2 - SPACE_600, true)}px`;
const primarySizes = `(max-width: ${BREAKPOINT_MD_SIZE}) ${getImageSize(BREAKPOINT_MD_SIZE - BREAKPOINT_MD_MARGIN * 2, false)}px, ${getImageSize((BREAKPOINT_LG_SIZE - BREAKPOINT_LG_MARGIN * 2) / 2 - SPACE_600, false)}px`;

type Props = {
  photo: PhotoHash;
  title: string;
  isPrimary?: boolean;
};

const PhotoGridImage = ({ photo, title, isPrimary }: Props) => {
  const getImageUrl = (photo: PhotoHash) => {
    if (photo?.id && photo?.photoHash) {
      return getPhotoUrl({ id: photo.id, photoHash: photo.photoHash }, PhotoSize.LargerWide, true);
    }
    return `/api/alltrails/photos/${photo.id}/image?size=large&key=${apiKey}`;
  };

  return (
    <Image
      className={styles.locationPhoto}
      src={getImageUrl(photo)}
      alt={title}
      fill
      sizes={isPrimary ? primarySizes : secondarySizes}
      quality={90}
      style={{ transition: 'all 0.3s ease-in-out' }}
      isLcp={isPrimary}
      handleLoadingState={!isPrimary}
    />
  );
};

export default PhotoGridImage;
