import Icon from '../components/Icon';

const Share = Icon(
  'share',
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.27 10.29a.75.75 0 0 0 0-1.08l-6.75-6.5a.75.75 0 0 0-1.27.54v3.523c-6.603.416-11.002 6.31-11.002 13.283a.75.75 0 0 0 1.5 0c0-3.058 2.155-4.878 4.678-5.97 1.853-.802 3.775-1.147 4.824-1.273v3.437a.75.75 0 0 0 1.27.54l6.75-6.5ZM7.83 12.71c-1.025.443-2.066 1.031-2.968 1.802C6.506 10.753 9.842 8.25 14 8.25a.75.75 0 0 0 .75-.75V5.013l4.919 4.737-4.919 4.737V12a.75.75 0 0 0-.75-.75c-.581 0-3.435.276-6.17 1.46Z"
    />
  </>
);

export default Share;
