'use client';

import classNames from 'classnames';
import { useIntl, defineMessages, FormattedMessage } from '@alltrails/shared/react-intl';
import logPhotoClicked from '@alltrails/analytics/events/logPhotoClicked';
import PageType from '@alltrails/analytics/enums/PageType';
import { MouseEvent, useCallback } from 'react';
import Button from '@alltrails/denali/components/Button';
import Gallery from '@alltrails/denali/icons/Gallery';
import { useLightbox } from '../Lightbox/LightboxContext';
import styles from './PhotoHeaderGrid.module.scss';
import PhotoGridImage from './PhotoGridImage';
import { PhotoHash } from '@/types/shared';

const messages = defineMessages({
  title: {
    defaultMessage: 'Trails in {list} | AllTrails.com'
  }
});

type Props = {
  photos: PhotoHash[];
  locationDetails: any;
  pageType?: PageType;
};

const PhotoHeaderGrid = ({ photos, locationDetails, pageType }: Props) => {
  const intl = useIntl();
  const { openLightbox } = useLightbox();
  const { type, locationSlug, name, city, state, country } = locationDetails;

  const handleOpenLightbox = useCallback(
    (e: MouseEvent<HTMLButtonElement>, photoId: number, index: number) => {
      e.stopPropagation();
      if (pageType) {
        logPhotoClicked({
          page_type: pageType,
          photo_id: String(photoId)
        });
      }
      openLightbox(e, index);
    },
    [openLightbox, pageType]
  );

  const getTitle = useCallback(
    (photoId: number) =>
      intl.formatMessage(messages.title, {
        list: [type === 'park' ? name : undefined, city, state, country].filter(_ => _).join(', ') + photoId
      }),
    [intl, type, name, city, state, country]
  );

  const title1 = getTitle(photos[0].id);
  const title2 = getTitle(photos[1].id);
  const title3 = getTitle(photos[2].id);
  const hasMorePhotos = photos.length > 3;

  return (
    <div className={classNames(styles.photoHeaderGridContainer)}>
      <button
        aria-label={title1}
        className={classNames(styles.imageContainer, styles.primaryImage, hasMorePhotos && styles.gradient)}
        onClick={e => handleOpenLightbox(e, photos[0].id, 0)}
      >
        <PhotoGridImage photo={photos[0]} title={title1} isPrimary />
      </button>
      {hasMorePhotos && locationDetails.type !== 'poi' && (
        <Button
          className={styles.showMore}
          size="sm"
          variant="inverse"
          testId="show-more-photos"
          text={<FormattedMessage defaultMessage="All photos" />}
          icon={{ Component: Gallery }}
          linkInfo={{ href: `/${locationSlug}/photos`, outsideOfMugen: true }}
        />
      )}
      <div className={classNames(styles.verticalImages)}>
        <button
          aria-label={title2}
          className={classNames(styles.imageContainer, styles.secondaryImage)}
          onClick={e => handleOpenLightbox(e, photos[1].id, 1)}
        >
          <PhotoGridImage photo={photos[1]} title={title2} />
        </button>
        <button
          aria-label={title3}
          className={classNames(styles.imageContainer, styles.secondaryImage)}
          onClick={e => handleOpenLightbox(e, photos[2].id, 2)}
        >
          <PhotoGridImage photo={photos[2]} title={title3} />
        </button>
      </div>
    </div>
  );
};

export default PhotoHeaderGrid;
