'use client';

import classNames from 'classnames';
import SwiperCarousel from '@alltrails/shared/components/SwiperCarousel';
import { useCallback, useEffect, useState } from 'react';
import PhotoSlash from '@alltrails/denali/icons/PhotoSlash';
import { BREAKPOINT_MD_SIZE } from '@alltrails/denali/tokens';
import { useWindowSize, Image as CoreImage } from '@alltrails/core';
import { PhotoSize } from '@alltrails/shared/types/photos';
import styles from './TopResults.module.scss';
import { getLocationPhotoUrls } from '@/utils/clientRequests';
import { SubLocationObject } from '@/types/subLocationListPages/shared';

const carouselBreakpoint = 1440;
const sizes = `(max-width: ${BREAKPOINT_MD_SIZE}px) 64px, (max-width: ${carouselBreakpoint}px) 180px, 240px`;

type Props = {
  area: SubLocationObject;
  defaultUrl?: string;
  photoType: string;
};

const AreaCarousel = ({ area, defaultUrl, photoType }: Props) => {
  const [cardHovered, setCardHovered] = useState(false);
  const { width } = useWindowSize();
  const isStaticImage = !!width && width < carouselBreakpoint;
  const isSmallSquare = !!width && width <= BREAKPOINT_MD_SIZE;
  const staticImageDimensions = isSmallSquare ? 64 : 180;
  const [photoUrls, setPhotoUrls] = useState(area.profile_photo_url ? [area.profile_photo_url] : []);
  const [photosLoaded, setPhotosLoaded] = useState(false);

  const loadPhotos = useCallback(() => {
    if (photosLoaded) return;

    setPhotosLoaded(true);

    try {
      getLocationPhotoUrls(photoType, area, 6, PhotoSize.Large).then(newPhotoUrls => {
        if (!newPhotoUrls || !newPhotoUrls.length) return;
        let validPhotoUrls = [...photoUrls];
        newPhotoUrls.forEach(photoUrl => {
          try {
            new Image().src = photoUrl;
            validPhotoUrls.push(photoUrl);
          } catch (error) {
            console.error(error);
          }
        });

        const uniquePhotoUrls = new Set(validPhotoUrls);
        setPhotoUrls(Array.from(uniquePhotoUrls));
      });
    } catch (error) {
      console.error(error);
    }
  }, [photosLoaded, photoType, area, photoUrls]);

  useEffect(() => {
    if (!defaultUrl && !photoUrls.length) {
      loadPhotos();
    }
  }, [defaultUrl, photoUrls, loadPhotos]);

  const onMouseEnter = () => {
    loadPhotos();
    setCardHovered(true);
  };

  const onMouseLeave = () => {
    setCardHovered(false);
  };

  if (!photoUrls) {
    return null;
  }

  if (isStaticImage) {
    const imageSrc = defaultUrl ?? photoUrls[0];
    return (
      <div className={classNames(styles.imageContainer, !imageSrc && styles.noImage)} data-testid={`${area.id}-one-photo`}>
        {imageSrc ? (
          <CoreImage
            alt={area.name}
            src={defaultUrl ?? photoUrls[0]}
            className={styles.image}
            sizes={sizes}
            loading="lazy"
            unoptimized
            width={staticImageDimensions}
            height={staticImageDimensions}
          />
        ) : (
          <PhotoSlash color="--color-text-secondary" size={isSmallSquare ? 'md' : 'lg'} />
        )}
      </div>
    );
  }

  return (
    <div
      className={classNames(styles.resultImage, photoUrls.length === 0 && styles.noImage)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-testid="area-carousel"
    >
      {photoUrls.length > 0 ? (
        <SwiperCarousel
          className={classNames(styles.imageContainer, cardHovered && styles.zoomPhoto)}
          uniqueId={`${area.id}`}
          items={photoUrls}
          hasPaginationGradient
          renderItem={(src, index) => (
            <div>
              <CoreImage
                alt={`${area.name}-${index}`}
                src={src}
                className={styles.image}
                sizes={sizes}
                loading="lazy"
                unoptimized
                width={240}
                height={180}
                handleLoadingState
              />
            </div>
          )}
          getItemKey={(src, index) => `PhotoCarousel_${index}_${src}`}
          arrowStyle="subtle"
          loop
          showPaginationDots
          hideSlideOverflow
        />
      ) : (
        <PhotoSlash color="--color-text-secondary" size="lg" />
      )}
    </div>
  );
};

export default AreaCarousel;
