import { AreaType } from '@alltrails/search/types/algoliaResultTypes';
import { Park } from '@/types/subLocationListPages/shared';

const convertSubLocationObjectToPark = (subLocationObject: Park) => ({
  _geoloc: {
    lat: subLocationObject.location.latitude,
    lng: subLocationObject.location.longitude
  },
  ID: String(subLocationObject.id),
  type: subLocationObject?.type as AreaType
});

export default convertSubLocationObjectToPark;
