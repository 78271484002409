import { FilterConfig } from '@alltrails/filters/types/filters';
import { FilterConfigToggleEntry } from '@alltrails/shared/types/filters';
import { PlaceType } from '@/types/locationDetailsPages/shared';

const getPlaceType = (filterConfig: FilterConfig): PlaceType | null => {
  const { baseFilters } = filterConfig;

  const keys = Object.keys(baseFilters);

  if (keys.length > 0) {
    const key = keys[0];
    switch (key) {
      case 'countries':
        return 'country' as PlaceType;
      case 'states':
        return 'state' as PlaceType;
      case 'cities':
        return 'city' as PlaceType;
      case 'areas':
        return 'area' as PlaceType;
      case 'poiIds':
        return 'poi' as PlaceType;
      default:
        return null;
    }
  }

  return null;
};

const getSelectedValue = (filterGroup: Record<string, FilterConfigToggleEntry>): string | number | null => {
  const selectedFilter = Object.values(filterGroup).find(filter => filter.selected);
  if (selectedFilter && (typeof selectedFilter.value === 'string' || typeof selectedFilter.value === 'number')) {
    return selectedFilter.value;
  }

  return null;
};

const getLocationId = (filterConfig: FilterConfig): string | number | null => {
  const filterGroups = Object.values(filterConfig.baseFilters) as Record<string, FilterConfigToggleEntry>[];
  return filterGroups.length > 0 ? getSelectedValue(filterGroups[0]) : null;
};

const getFilters = (filterConfig: FilterConfig, filterKey: 'features' | 'activities' | 'access'): string[] | null => {
  const group = filterConfig.baseFilters[filterKey];
  const selectedItem = group ? (Object.values(group)[0] as FilterConfigToggleEntry) : null;
  return selectedItem?.selected && typeof selectedItem.value === 'string' ? [selectedItem.value] : null;
};

export { getPlaceType, getLocationId, getFilters };
