import { ComponentProps } from 'react';
import Carousel from '@alltrails/denali/components/Carousel';

// This number optimizes the card size for 390px screens.
// At that width, this gives ~40px of space between the
// right side of the card and the edge of the screen.
export const slidesPerViewMobile = 1.05;

export const slidesPerViewBreakpoints: ComponentProps<typeof Carousel>['slidesPerViewBreakpoints'] = { 541: 2, 1121: 4 };

export const maxSlideWidth = 504; // at 1120px screen width, we render 2 cards. 1120 - 96 margin - 16 between cards = 1008px. 1008 / 2 = 504px
