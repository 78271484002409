'use client';

import { ComponentProps } from 'react';
import classNames from 'classnames';
import logBreadcrumbLinkClicked from '@alltrails/analytics/events/logBreadcrumbLinkClicked';
import PageType from '@alltrails/analytics/enums/PageType';
import Breadcrumb from '@alltrails/denali/components/Breadcrumb';
import Link from '@alltrails/denali/components/Link';
import FormattedMessage from '../FormattedMessage';
import styles from './Breadcrumbs.module.scss';

type Props = {
  breadcrumbItems: ComponentProps<typeof Breadcrumb>['breadcrumbItems'];
  className?: string;
  expandLastItem?: boolean;
  includeUrlForLastItem?: boolean;
  pageType?: PageType;
  baseUrl: string;
};

// https://www.figma.com/file/fYu56sVXO70II5tQn4rwx3/Location-Page-Web-%5BHandoff%5D?type=design&node-id=150-106202

const Breadcrumbs = ({ breadcrumbItems, className, expandLastItem = false, includeUrlForLastItem = false, pageType, baseUrl }: Props) => (
  <div className={classNames(styles.breadcrumbWrapper, className)}>
    <Link
      href="/explore"
      prefetch={false}
      className={styles.breadcrumbExploreLink}
      onClick={() => {
        if (pageType) {
          logBreadcrumbLinkClicked({ click_text: 'Back to Explore', page_type: pageType, position_available: '0', url: '/explore' });
        }
      }}
    >
      <FormattedMessage defaultMessage="Back to Explore" />
    </Link>
    <Breadcrumb
      breadcrumbItems={breadcrumbItems}
      className={classNames(styles.breadcrumbs, { [styles.truncateLastItem]: !expandLastItem })}
      showCurrent
      testId="layout-breadcrumbs"
      includeUrlForLastItem={includeUrlForLastItem}
      baseUrl={baseUrl}
    />
  </div>
);

export default Breadcrumbs;
