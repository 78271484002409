'use client';

import dynamic from 'next/dynamic';
import ShimmerWrapper from '@alltrails/shared/components/ShimmerWrapper';
import { MapProps } from './LocationMapWrapper';
import styles from './LocationMap.module.scss';

const LocationMapWrapper = dynamic(() => import('./LocationMapWrapper'), {
  ssr: false,
  loading: () => (
    <div className={styles.mapContainer}>
      <ShimmerWrapper className={styles.shimmerContainer}>
        <div />
      </ShimmerWrapper>
    </div>
  )
});

const DynamicLocationMap = (props: MapProps) => {
  return <LocationMapWrapper {...props} />;
};

export default DynamicLocationMap;
