import { get } from '@alltrails/shared/api';
import { logError } from '@alltrails/core';
import { PhotoSize } from '@alltrails/shared/types/photos';
import { getPhotoUrl } from '@alltrails/shared/utils/requests/photoRequests';
import { PhotosResponse } from '@alltrails/shared/types/lightbox';

export const getLocationPhotoUrls = async (type, location, perPage = 5, size = PhotoSize.Large) => {
  try {
    const params = new URLSearchParams();
    params.append('per_page', String(perPage));
    if (type === 'states') {
      params.append('country_id', String(location.country));
    }

    const data = await get<{ photos: PhotosResponse[] }>(`/api/alltrails/locations/${type}/${location.id}/photos?${params.toString()}`);
    if (data?.photos) {
      return data.photos.map(photo => getPhotoUrl({ id: photo.id, photoHash: photo.photoHash }, size));
    } else {
      return [];
    }
  } catch (e) {
    logError(new Error(`Error when getting location photo urls: ${e}`));
    return [];
  }
};
