import classNames from 'classnames';
import styles from './Divider.module.scss';

type DividerProps = {
  className?: string;
};

const Divider = ({ className }: DividerProps) => {
  return (
    <div className={classNames(styles.dividerContainer, className)}>
      <div className={styles.divider} />
    </div>
  );
};

export default Divider;
