'use client';

import { Trail } from '@alltrails/shared/types/trail';
import classNames from 'classnames';
import SwiperCarousel from '@alltrails/shared/components/SwiperCarousel';
import { useState } from 'react';
import { BREAKPOINT_MD_SIZE } from '@alltrails/denali/tokens';
import { useWindowSize, Image } from '@alltrails/core';
import styles from './TopResults.module.scss';
import usePhotos from '@/hooks/usePhotos';

const carouselBreakpoint = 1440;
const sizes = `(max-width: ${BREAKPOINT_MD_SIZE}px) 64px, (max-width: ${carouselBreakpoint}px) 180px, 240px`;

type Props = {
  trail: Trail;
  defaultUrl?: string;
};

const TrailCarousel = ({ trail, defaultUrl }: Props) => {
  const [cardHovered, setCardHovered] = useState(false);
  const { width } = useWindowSize();
  const isStaticImage = !!width && width < carouselBreakpoint;
  const isSmallSquare = !!width && width <= BREAKPOINT_MD_SIZE;
  const staticImageDimensions = isSmallSquare ? 64 : 180;
  const { photos, loadPhotos } = usePhotos(trail);

  const onMouseEnter = () => {
    loadPhotos();
    setCardHovered(true);
  };

  const onMouseLeave = () => {
    setCardHovered(false);
  };

  if (isStaticImage) {
    return (
      <div className={styles.imageContainer} data-testid={`${trail.id}-one-photo`}>
        <Image
          alt={trail.name}
          src={defaultUrl ?? photos[0]}
          className={styles.image}
          sizes={sizes}
          loading="lazy"
          unoptimized
          width={staticImageDimensions}
          height={staticImageDimensions}
        />
      </div>
    );
  }

  return (
    <div className={styles.resultImage} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} data-testid="trail-carousel">
      {photos.length > 0 && (
        <SwiperCarousel
          className={classNames(styles.imageContainer, cardHovered && styles.zoomPhoto)}
          uniqueId={`${trail.id}`}
          items={photos}
          hasPaginationGradient
          renderItem={(src, index) => (
            <Image
              alt={`${trail.name}-${index}`}
              src={src}
              className={styles.image}
              sizes={sizes}
              loading="lazy"
              unoptimized
              width={240}
              height={180}
              handleLoadingState
            />
          )}
          getItemKey={(src, index) => `PhotoCarousel_${index}_${src}`}
          onFirstTouch={loadPhotos}
          arrowStyle="subtle"
          loop
          showPaginationDots
          hideSlideOverflow
        />
      )}
    </div>
  );
};

export default TrailCarousel;
