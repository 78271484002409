'use client';

import { default as BreadcrumbLayout } from '@alltrails/layout/components/Breadcrumbs';
import { usePublicBaseUrl } from '@alltrails/context';
import logBreadcrumbLinkClicked from '@alltrails/analytics/events/logBreadcrumbLinkClicked';
import PageType from '@alltrails/analytics/enums/PageType';
import styles from './Breadcrumbs.module.scss';
import { LocationDetailsApiResponse } from '@/types/locationDetailsPages/shared';

type BreadcrumbsProps = {
  orderedBreadcrumbList: LocationDetailsApiResponse['orderedBreadcrumbList'];
  pageType?: PageType;
};

const Breadcrumbs = ({ orderedBreadcrumbList, pageType }: BreadcrumbsProps) => {
  const publicBaseUrl = usePublicBaseUrl();

  const mappedBreadcrumbs = orderedBreadcrumbList.map(breadcrumb => ({
    name: breadcrumb.name,
    url: `/${breadcrumb.urlPath}`,
    onClick: (index: number) => {
      if (pageType) {
        logBreadcrumbLinkClicked({
          click_text: breadcrumb.name,
          page_type: pageType,
          position_available: String(index),
          url: String(breadcrumb.urlPath)
        });
      }
    }
  }));

  return <BreadcrumbLayout breadcrumbItems={mappedBreadcrumbs} className={styles.breadcrumbs} pageType={pageType} baseUrl={publicBaseUrl} />;
};

export default Breadcrumbs;
