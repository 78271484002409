import Icon from '../components/Icon';

const Car = Icon(
  'car',
  <>
    <path d="M7 13a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2zM16 14a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H17a1 1 0 0 1-1-1" />
    <path
      fillRule="evenodd"
      d="M15.653 4.25H8.408A2.75 2.75 0 0 0 5.8 5.979L4.726 8.665 3.53 7.47a.75.75 0 0 0-1.06 1.06l1.112 1.113A2.75 2.75 0 0 0 2.25 12v4c0 1.259.846 2.32 2 2.646V20a.75.75 0 0 0 1.5 0v-1.25h12.5V20a.75.75 0 0 0 1.5 0v-1.354a2.75 2.75 0 0 0 2-2.646v-4c0-1-.534-1.876-1.332-2.357L21.53 8.53a.75.75 0 0 0-1.06-1.06l-1.2 1.2-1.071-2.643a2.75 2.75 0 0 0-2.546-1.777m3.347 13c.69 0 1.25-.56 1.25-1.25v-4a1.25 1.25 0 0 0-1.245-1.25H4.995A1.25 1.25 0 0 0 3.75 12v4c0 .69.56 1.25 1.25 1.25zm-1.113-8-1.082-2.668-.008-.02a1.25 1.25 0 0 0-1.155-.812H8.383a1.25 1.25 0 0 0-1.19.785L6.108 9.25z"
      clipRule="evenodd"
    />
  </>
);

export default Car;
