import classNames from 'classnames';
import Divider from '../Divider/Divider';
import styles from './PageSection.module.scss';

type PageSectionProps = {
  hasDividerTop?: boolean;
  hideXOverflow?: boolean;
  children: React.ReactNode;
  containerClassName?: string;
};

const PageSection = ({ hasDividerTop = true, hideXOverflow, children, containerClassName }: PageSectionProps) => {
  const pageSectionWithMargins = (
    <>
      {hasDividerTop && <Divider />}
      <div className={classNames(styles.container, containerClassName)}>
        {hasDividerTop && hideXOverflow && <div className={styles.dividerPadding} />}
        {children}
      </div>
    </>
  );

  if (hideXOverflow) {
    return <div className={styles.hideXOverflow}>{pageSectionWithMargins}</div>;
  }

  return pageSectionWithMargins;
};

export default PageSection;
