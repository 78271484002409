import Link from '../Link';
import styles from './styles/styles.module.scss';

export type Props = { url?: string; name: string; index: number; isLast?: boolean; onClick?: (index: number) => void };

export default function BreadcrumbItem({ url, name, index, isLast = false, onClick }: Props) {
  return (
    <li className={styles.breadcrumbItem}>
      {url ? (
        <Link className={styles.breadcrumbItemLink} href={url} onClick={() => onClick?.(index)}>
          <span>{name}</span>
        </Link>
      ) : (
        <span className={styles.lastBreadcrumbItem}>{name}</span>
      )}
      {isLast ? '' : <span className={styles.divider}>/</span>}
    </li>
  );
}
