import { ComponentType, useState, useCallback, useEffect, useMemo } from 'react';
import { ModalPortal } from '@alltrails/core';

/**
 * @deprecated This doesn't actually save much, stop using it so we can delete it once we have all the modals from the monorepo ported to mugen
 */
const useModal = <
  P extends {
    closeModal: () => void;
    [key: string]: unknown;
  }
>(
  Component: ComponentType<P>,
  memoizedComponentProps: Omit<P, 'closeModal'>
) => {
  const [isOpen, toggleOpen] = useState(false);
  const [modalProps, setModalProps] = useState(memoizedComponentProps);

  useEffect(() => {
    setModalProps(memoizedComponentProps);
  }, [memoizedComponentProps]);

  const openModal = useCallback((startingIndex?: number) => {
    if (startingIndex) {
      setModalProps({ ...modalProps, startingIndex });
    }
    toggleOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    toggleOpen(false);
  }, []);

  const modal = useMemo(() => {
    if (!isOpen) return <></>;

    return (
      <ModalPortal>
        <Component {...(modalProps as P)} closeModal={closeModal} />
      </ModalPortal>
    );
  }, [isOpen, Component, modalProps, closeModal]);

  return { modal, openModal, closeModal };
};

export default useModal;
