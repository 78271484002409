import { Area } from '@alltrails/shared/types/area';
import type { TrailSearchResult as AlgoliaTrail } from '@alltrails/search/types/algoliaResultTypes';
import { Trail, TrailPhoto } from '@alltrails/shared/types/trail';

// Remove the "trail/"" from the trail slug so that we can treat the slug like all others (no path info)
const formatTrailSlug = (slug: string) => (slug.startsWith('trail/') ? slug.slice(6) : slug);

export default function trailFromAlgoliaTrail(trail: AlgoliaTrail): Trail {
  return {
    id: trail.ID,
    name: trail.name,
    slug: formatTrailSlug(trail.slug),
    area: { name: trail.area_name, slug: trail.area_slug } as Area,
    avgRating: trail.avg_rating || 0,
    defaultActivityStats: { difficulty: Number(trail.difficulty_rating), rating: trail.avg_rating },
    defaultPhoto: trail.has_profile_photo || trail.profile_photo_url ? ({} as TrailPhoto) : undefined,
    trailCounts: { reviewCount: trail.num_reviews || 0 },
    trailDetail: { description: trail.description },
    trailGeoStats: { durationMinutes: trail.duration_minutes?.toString() || '0', length: trail.length || 0 }
  };
}
