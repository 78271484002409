'use client';

import { createContext, useContext, useState } from 'react';

const ShareModalContext = createContext({
  isModalOpen: false,
  openModal: () => {},
  closeModal: () => {}
});

export const ShareModalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return <ShareModalContext.Provider value={{ isModalOpen, openModal, closeModal }}>{children}</ShareModalContext.Provider>;
};

export const useModal = () => useContext(ShareModalContext);
