'use client';

import { ComponentProps } from 'react';
import { FormattedMessage as OriginalFormattedMessage } from 'react-intl';

// The purpose of this component is to include 'use client' so that parent components
// can more easily be server components
const FormattedMessage = (props: ComponentProps<typeof FormattedMessage>) => <OriginalFormattedMessage {...props} />;

export default FormattedMessage;
