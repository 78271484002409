import { useEffect, useState } from 'react';

type Props = {
  children: React.ReactNode;
  defer?: boolean;
  fallback?: React.ReactNode;
};

// Based on https://mui.com/base-ui/react-no-ssr/components-api/

const NoSsr = ({ children, fallback }: Props) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return <>{mounted ? children : fallback}</>;
};

export default NoSsr;
