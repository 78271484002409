import { useUser } from '@alltrails/context';
import { useSearchParams } from 'next/navigation';

const useShouldRenderPlusCta = () => {
  const user = useUser();
  const searchParams = useSearchParams();

  const offerTrial = searchParams.get('offer-trial') === 'true';

  const shouldShowPromo = !user || (!user.pro && user.isPromoEligible);
  const renderPlusCta = offerTrial && shouldShowPromo;

  return renderPlusCta;
};

export default useShouldRenderPlusCta;
