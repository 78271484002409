'use client';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PageSectionType from '@alltrails/analytics/enums/PageSectionType';
import useLogWebSectionViewed from '@alltrails/layout/hooks/useLogWebSectionViewed';
import { BoundingBox, useGetLocationDataMutation } from '@alltrails/explore-service';
import Button from '@alltrails/denali/components/Button';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Typography } from '@alltrails/core';
import { useLocationMap } from '../LocationMap/LocationMapContext';
import TopAreasCard from './TopAreasCard';
import styles from './TopResults.module.scss';
import { getAnalyticsPageType } from '@/utils/getAnalyticsPageType';
import { Park, SubLocationObject } from '@/types/subLocationListPages/shared';
import convertSubLocationObjectToPark from '@/utils/convertSubLocationObjectToPark';

type Props = {
  photoType: string;
  totalAreas: number;
};

const TopAreas = ({ photoType, totalAreas }: Props) => {
  const pageType = getAnalyticsPageType();
  const ref = useRef<HTMLDivElement>(null);
  useLogWebSectionViewed(ref, PageSectionType.Highlights, pageType, { threshold: 0.1 });
  const { setCardHoveredPark, setHoveredAreaBBox, handleGetMoreAreas, areasToRender, setHoveredCityOrState } = useLocationMap();
  const [boundingBoxes, setBoundingBoxes] = useState<Record<string, BoundingBox>>({});
  const [getLocationData] = useGetLocationDataMutation();
  const isParkListPage = photoType === 'parks';
  const [tempHoveredArea, setTempHoveredArea] = useState<SubLocationObject | undefined>();
  const [confirmedHoverId, setConfirmedHoverId] = useState<number | null>(null);

  const handleSetHoveredCityState = useCallback(
    (cityState: SubLocationObject | undefined) => {
      setTempHoveredArea(cityState);
      setHoveredCityOrState(cityState);
    },
    [setTempHoveredArea, setHoveredCityOrState]
  );

  const handleSetHoveredPark = useCallback(
    (park: Park) => {
      setCardHoveredPark(convertSubLocationObjectToPark(park));
      setTempHoveredArea(park);
    },
    [setCardHoveredPark]
  );

  const handleParkMouseLeave = useCallback(() => {
    setCardHoveredPark(undefined);
    setTempHoveredArea(undefined);
  }, [setCardHoveredPark]);

  const shouldShowMoreButton = useMemo(
    () => (photoType === 'states' || photoType === 'parks') && totalAreas > 10 && areasToRender && areasToRender.length < totalAreas,
    [areasToRender, photoType, totalAreas]
  );
  const formatttedMaxAreas = <FormattedNumber value={areasToRender?.length} />;
  const formattedTotalAreas = <FormattedNumber value={totalAreas} />;

  const handleSetBoundingBox = useCallback(
    async (item: SubLocationObject) => {
      if (item.location) {
        const placeType = photoType === 'cities' ? 'city' : photoType === 'states' ? 'state' : 'area';
        const boundingBox = boundingBoxes?.[String(item.id)];

        if (boundingBox) {
          setHoveredAreaBBox(boundingBox);
        } else {
          try {
            const response = await getLocationData({ location: { id: item.id, placeType } }).unwrap();
            setHoveredAreaBBox(response.boundingBox);
            setBoundingBoxes(prevBoundingBoxes => ({
              ...prevBoundingBoxes,
              [String(item.id)]: response.boundingBox
            }));
          } catch (error) {
            console.error('Error fetching city or state bounding box', error);
          }
        }
      }
    },
    [photoType, setHoveredAreaBBox, getLocationData, boundingBoxes]
  );

  useEffect(() => {
    let hoverTimeout: string | number | NodeJS.Timeout | undefined;

    if (tempHoveredArea) {
      hoverTimeout = setTimeout(() => {
        // Don't move the map if the user has moved the mouse off of this card
        setConfirmedHoverId(tempHoveredArea.id);
      }, 1000);
    } else {
      setConfirmedHoverId(null);
    }

    return () => clearTimeout(hoverTimeout);
  }, [tempHoveredArea]);

  useEffect(() => {
    if (!!confirmedHoverId && !!tempHoveredArea && confirmedHoverId === tempHoveredArea.id) {
      handleSetBoundingBox(tempHoveredArea!);
    }
  }, [confirmedHoverId, handleSetBoundingBox, tempHoveredArea]);

  const handleMouseEnter = useCallback(
    (item: SubLocationObject) => (isParkListPage ? handleSetHoveredPark(item as Park) : handleSetHoveredCityState(item)),
    [isParkListPage, handleSetHoveredPark, handleSetHoveredCityState]
  );

  const handleMouseLeave = useCallback(
    () => (isParkListPage ? handleParkMouseLeave() : handleSetHoveredCityState(undefined)),
    [isParkListPage, handleSetHoveredCityState, handleParkMouseLeave]
  );

  return (
    <div ref={ref} className={styles.topResultsContainer}>
      {areasToRender?.map((area, idx) => {
        return (
          <TopAreasCard
            key={area.id}
            area={area}
            ranking={idx + 1}
            showBorder={idx !== areasToRender.length - 1}
            photoType={photoType}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
          />
        );
      })}
      {shouldShowMoreButton && (
        <div className={styles.showMoreAreas}>
          <Button
            testId="states-parks-show-more"
            text={<FormattedMessage defaultMessage="Show more" />}
            variant="primary"
            onClick={handleGetMoreAreas}
          />
          <Typography variant="text100">
            <FormattedMessage
              defaultMessage="Showing results {min}–{max} of {total}"
              values={{ min: 1, max: formatttedMaxAreas, total: formattedTotalAreas }}
            />
          </Typography>
        </div>
      )}
    </div>
  );
};

export default TopAreas;
