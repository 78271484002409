'use client';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { getTrailProfilePhoto, getCardPhotoUrl } from '@alltrails/shared/utils/requests/photoRequests';
import { PhotoSize } from '@alltrails/shared/types/photos';
import { Trail } from '@alltrails/shared/types/trail';
import { usePublicBaseUrl } from '@alltrails/context';

const cardType = 'trails';
type Size = 'smallSquare' | 'large';

const usePhotos = (trail: Trail, size?: Size) => {
  const { id, defaultPhoto } = trail;
  const hasProfilePhoto = !!defaultPhoto;
  const sizeToUse = size === 'smallSquare' ? PhotoSize.MediumSquare : PhotoSize.Large;
  const mapSizeToUse = size === 'smallSquare' ? '200x200' : '512x217';

  const [photos, setPhotos] = useState<string[]>([]);
  const [photosLoaded, setPhotosLoaded] = useState(false);
  const baseUrl = usePublicBaseUrl();

  useEffect(() => {
    const initialPhoto = getCardPhotoUrl(cardType, id, 0, sizeToUse, baseUrl);
    const fillerPhotos = Array(5).fill('') as string[];
    setPhotos([initialPhoto, ...fillerPhotos]);
    setPhotosLoaded(false);
  }, [baseUrl, id, sizeToUse]);

  const loadPhotos = useCallback(() => {
    if (!photosLoaded) {
      setPhotosLoaded(true);

      const urls = photos.map((_, index) => {
        const url = getCardPhotoUrl(cardType, id, index, sizeToUse, baseUrl);
        // pre-load images by browser
        try {
          new Image().src = url;
        } catch (error) {
          // silently catch
        }
        return url;
      });

      setPhotos(urls);
    }
  }, [photosLoaded, photos, id, sizeToUse, baseUrl]);

  const photosToReturn = useMemo(
    // Fetch the static map directly as the fallback profile photo if there is no defaultPhoto:
    () => (hasProfilePhoto ? photos : [getTrailProfilePhoto(id, sizeToUse, mapSizeToUse, baseUrl), ...photos]),
    [hasProfilePhoto, photos, id, sizeToUse, mapSizeToUse, baseUrl]
  );

  return useMemo(() => ({ photos: photosToReturn, loadPhotos }), [loadPhotos, photosToReturn]);
};

export default usePhotos;
