import Icon from '../components/Icon';

const Print = Icon(
  'print',
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 3a.75.75 0 0 0-.75.75v3H4.247c-1.248 0-2.372.956-2.372 2.25v7.5c0 .414.336.75.75.75H5.25v3.375c0 .414.336.75.75.75h12a.75.75 0 0 0 .75-.75V17.25h2.625a.75.75 0 0 0 .75-.75V9c0-1.294-1.124-2.25-2.372-2.25H18.75v-3A.75.75 0 0 0 18 3H6ZM4.247 8.25h15.506c.533 0 .872.394.872.75v6.75H18.75v-1.5a.75.75 0 0 0-.75-.75H6a.75.75 0 0 0-.75.75v1.5H3.375V9c0-.356.339-.75.872-.75Zm2.503-1.5h10.5V4.5H6.75v2.25Zm0 8.25h10.5v4.875H6.75V15Zm12-4.125a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0Z"
    />
  </>
);

export default Print;
