'use client';

import dynamic from 'next/dynamic';
import { ShareObject } from '@alltrails/sharing';
import { useModal } from './ShareModalContext';

const DynamicShareModal = dynamic(() => import('@alltrails/sharing').then(mod => mod.ShareModal), { ssr: false });

type Props = {
  shouldShowEmbed?: boolean;
  shareObject: ShareObject;
};

const ShareModalWrapper = ({ shouldShowEmbed, shareObject }: Props) => {
  const { isModalOpen, closeModal } = useModal();

  if (!isModalOpen) {
    return null;
  }

  return <DynamicShareModal isOpen={isModalOpen} onClose={closeModal} shareObject={shareObject} shouldShowEmbed={shouldShowEmbed} />;
};

export default ShareModalWrapper;
