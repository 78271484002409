import { get } from '@alltrails/shared/api';
import { List, ListItems } from '@alltrails/shared/types/lists';

export type ListsAndListItemsResponse = {
  lists: {
    lists: List[];
    listItems: ListItems;
  };
};

const getListsAndListItems = async () => {
  const {
    lists: { lists, listItems }
  } = await get<ListsAndListItemsResponse>('/api/alltrails/web/lists');
  return { lists, listItems };
};

export default getListsAndListItems;
