import { KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Pagination, Swiper as SwiperType } from 'swiper';
import { SwiperModule } from 'swiper/types/shared';
import { PaginationOptions } from 'swiper/types/modules/pagination';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { SPACE_16, SPACE_8 } from '../../tokens';
import styles from './styles/styles.module.scss';
import { LeftArrow, RightArrow } from './ArrowButtons';
import PaginationWrapper from './PaginationWrapper';

// https://www.figma.com/design/9Y1n3VoMJAcTGPe82bvySj/Denali-Components?node-id=11978-18316&t=4olhoU406sZa5cRT-0

const PAGINATION_WRAPPER_CLASS = 'swiper-pagination';

type SlidesPerViewBreakpoints = {
  [screenSize: number]: number; // Example {760: 3} when window width is >= 760px, 3 slides will be shown
};

type SwiperCarouselProps<T> = {
  uniqueId: string;
  items: T[];
  renderItem: (item: T, index: number) => JSX.Element;
  getItemKey: (item: T, index: number) => string;
  className?: string;
  loop?: boolean; // Loop will not work for SSR (https://github.com/nolimits4web/swiper/issues/5673)
  absoluteSizing?: boolean; // When absolute sizing is used, viewItemCount props will be ignored
  slidesPerViewMobile?: number;
  slidesPerViewBreakpoints?: SlidesPerViewBreakpoints;
  slideAsSet?: boolean;
  customPagingBulletsClassName?: string;
  showPaginationDots?: boolean;
  showOverflow?: boolean;
  isLoading?: boolean;
  renderLoadingItem?: () => JSX.Element;
  staticArrows?: boolean; // Show arrows by default on non-touch devices
  scrollMode?: boolean;
  onFirstTouch?: () => void;
  onSlideChange?: (index: number) => void;
  onStartInteracting?: () => void;
  onStopInteracting?: () => void;
  initialSlideIndex?: number;
  preventActiveElementBlur?: boolean;
  cardAspectRatio?: string; // Example '16/9' (and the renderItem card should have a height of 100%)
};

const SwiperCarousel = <T,>({
  uniqueId,
  items,
  renderItem,
  getItemKey,
  className,
  loop = false,
  absoluteSizing = false,
  slidesPerViewMobile = 1.5,
  slidesPerViewBreakpoints = { 760: 3, 1120: 4 },
  slideAsSet = false,
  customPagingBulletsClassName,
  showPaginationDots = true,
  showOverflow = true,
  isLoading = false,
  renderLoadingItem,
  staticArrows,
  scrollMode,
  onSlideChange,
  onFirstTouch,
  onStartInteracting,
  onStopInteracting,
  initialSlideIndex = 0,
  preventActiveElementBlur = false,
  cardAspectRatio
}: SwiperCarouselProps<T>): JSX.Element => {
  const intl = useIntl();

  const [swiper, setSwiper] = useState<SwiperType>();
  const [showPrevArrow, setShowPrevArrow] = useState(false);
  const [showNextArrow, setShowNextArrow] = useState(false);
  const [onFirstTouchCalled, setOnFirstTouchCalled] = useState(false);

  const updateShowArrows = () => {
    if (scrollMode) {
      setShowPrevArrow(false);
      setShowNextArrow(false);
    } else if (loop) {
      setShowPrevArrow(true);
      setShowNextArrow(true);
    } else if (swiper) {
      setShowPrevArrow(!swiper.isBeginning);
      setShowNextArrow(!swiper.isEnd);
    } else {
      setShowPrevArrow(true);
      setShowNextArrow(true);
    }
  };

  useEffect(updateShowArrows, [loop, scrollMode, swiper]);

  useEffect(() => {
    if (swiper) {
      if (onFirstTouch) {
        swiper.once('touchStart', () => {
          if (!onFirstTouchCalled) {
            onFirstTouch();
            setOnFirstTouchCalled(true);
          }
        });
      }
      swiper.on('slideChangeTransitionEnd', () => {
        if (onFirstTouch && !onFirstTouchCalled) {
          onFirstTouch(); // If the user is navigating with a keyboard, they will never trigger the true "first touch" action
          setOnFirstTouchCalled(true);
        }
        if (onSlideChange) {
          onSlideChange(swiper.realIndex);
        }
      });
      swiper.on('transitionEnd', () => {
        updateShowArrows();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swiper]);

  useEffect(() => {
    if (!swiper || !onStartInteracting || !onStopInteracting) {
      return;
    }

    swiper.on('transitionStart', onStartInteracting);
    swiper.on('sliderFirstMove', onStartInteracting);
    swiper.on('touchMove', onStartInteracting);

    swiper.on('transitionEnd', onStopInteracting);
    swiper.on('touchEnd', onStopInteracting);

    // eslint-disable-next-line consistent-return
    return () => {
      swiper.off('transitionStart', onStartInteracting);
      swiper.off('sliderFirstMove', onStartInteracting);
      swiper.off('touchMove', onStartInteracting);
      swiper.off('transitionEnd', onStopInteracting);
      swiper.off('touchEnd', onStopInteracting);
    };
  }, [onStartInteracting, onStopInteracting, swiper]);
  const prevSlide = () => {
    swiper?.slidePrev();
  };

  const nextSlide = () => {
    swiper?.slideNext();
  };

  const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    switch (e.key) {
      case 'ArrowLeft':
        prevSlide();
        break;
      case 'ArrowRight':
        nextSlide();
        break;
      default:
      // do nothing
    }
  };

  const modules = [Pagination, scrollMode && FreeMode].filter(module => !!module) as SwiperModule[];

  const paginationOptions: PaginationOptions | undefined = useMemo(() => {
    if (showPaginationDots) {
      return {
        isClickable: scrollMode,
        dynamicBullets: true,
        dynamicMainBullets: 3,
        el: `.${PAGINATION_WRAPPER_CLASS}`,
        renderBullet: (index, className) => `<span class="${classNames(className, customPagingBulletsClassName)}">${index + 1}</span>`
      };
    }
    return { isClickable: scrollMode, renderBullet: () => '' };
  }, [scrollMode, showPaginationDots, customPagingBulletsClassName]);

  const slidesPerGroup = useMemo(() => {
    if (slideAsSet) {
      return absoluteSizing ? undefined : slidesPerViewMobile;
    }
    return 1;
  }, [slideAsSet, absoluteSizing, slidesPerViewMobile]);

  // This is necessary for setting the CSS for the swiper slide width server-side
  // Unfortunately, the Swiper library sets responsive card width client-side
  // so we can end up with a flash of unstyled content.
  // Add breakpoints here if necessary - the set is still TBD by design: https://alltrails.slack.com/archives/C01PU0Y2SHW/p1718740270078469
  const ssrStyleMapping = {
    'mobile-1.5': styles.swiperSlideMobile1x5,
    'mobile-2': styles.swiperSlideMobile2,
    '541-2': styles.swiperSlide541x2,
    '541-3': styles.swiperSlide541x3,
    '760-2': styles.swiperSlide760x2,
    '760-3': styles.swiperSlide760x3,
    '760-4': styles.swiperSlide760x4,
    '1121-3': styles.swiperSlide1121x3,
    '1121-4': styles.swiperSlide1121x4,
    '1121-5': styles.swiperSlide1121x5
  };

  const breakpoints = {
    '361': {
      spaceBetween: SPACE_16
    }
  };
  let ssrSwiperSlideClassNames = [] as string[];
  if (!absoluteSizing) {
    Object.keys(slidesPerViewBreakpoints).forEach(breakpoint => {
      const slideCount = slidesPerViewBreakpoints[breakpoint];
      breakpoints[breakpoint] = {
        slidesPerView: slideCount,
        slidesPerGroup: slideAsSet ? slideCount : 1,
        spaceBetween: SPACE_16
      };

      ssrSwiperSlideClassNames.push(ssrStyleMapping[`${breakpoint}-${slideCount}`]);
    });
  }

  let carouselItems = items;
  let carouselRenderItem = renderItem;
  if (isLoading) {
    carouselItems = Array(6).fill(0);
    carouselRenderItem =
      renderLoadingItem || (() => <div className={classNames(styles.defaultLoadingItem, { [styles.absoluteSizeLoadingItem]: absoluteSizing })} />);
  } else if (cardAspectRatio) {
    carouselRenderItem = (item, index) => <div style={{ aspectRatio: cardAspectRatio }}>{renderItem(item, index)}</div>;
  }

  return (
    <div className={classNames(styles.container, { [styles.showArrows]: staticArrows })} data-testid={uniqueId}>
      <div className={classNames(styles.swiperContainer, showOverflow && styles.showOverflow, className)}>
        <Swiper
          className={classNames(styles.swiper, absoluteSizing && styles.autoSize)}
          speed={slideAsSet ? 500 : 300}
          onSwiper={setSwiper}
          modules={modules.length ? modules : undefined}
          pagination={paginationOptions || undefined}
          loop={loop}
          spaceBetween={SPACE_8}
          freeMode={scrollMode}
          slidesPerGroup={slidesPerGroup}
          slidesPerGroupAuto={slideAsSet && absoluteSizing}
          slidesPerView={absoluteSizing ? 'auto' : slidesPerViewMobile}
          breakpoints={breakpoints}
          initialSlide={initialSlideIndex}
          focusableElements={preventActiveElementBlur ? 'video video video' : undefined} // Need a valid css selector that targets no elements
        >
          {carouselItems.map((item, index) => (
            <SwiperSlide className={classNames(styles.swiperSlide, ...ssrSwiperSlideClassNames)} key={getItemKey(item, index)} onKeyDown={onKeyDown}>
              {carouselRenderItem(item, index)}
            </SwiperSlide>
          ))}
          {showPaginationDots && <PaginationWrapper paginationElementClass={PAGINATION_WRAPPER_CLASS} />}
        </Swiper>
      </div>
      {showPrevArrow ? (
        <LeftArrow
          className={classNames({ [styles.adjustArrowsForPagination]: showPaginationDots })}
          onClick={prevSlide}
          uniqueId={uniqueId}
          title={intl.formatMessage({
            defaultMessage: 'Navigate previous'
          })}
        />
      ) : null}
      {showNextArrow ? (
        <RightArrow
          className={classNames({ [styles.adjustArrowsForPagination]: showPaginationDots })}
          onClick={nextSlide}
          uniqueId={uniqueId}
          title={intl.formatMessage({ defaultMessage: 'Navigate next' })}
        />
      ) : null}
    </div>
  );
};

export default SwiperCarousel;
