import { PropsWithChildren, Suspense } from 'react';
import NoSsr from './NoSsr';

type Props = PropsWithChildren & {
  fallback?: React.ReactNode;
};

/**
 * A non-SSR version of `Suspense`. Using `Suspense` directly in SSR may cause
 * hydration mismatch errors, so we use `NoSsr` to defer the rendering of
 * `Suspense` to the client side.
 *
 * See https://jser.dev/react/2023/03/27/hydration-with-suspense/
 */
const NoSsrSuspense = ({ children, fallback }: Props) => (
  <NoSsr fallback={fallback}>
    <Suspense fallback={fallback ?? null}>{children}</Suspense>
  </NoSsr>
);

export default NoSsrSuspense;
