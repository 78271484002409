import classNames from 'classnames';
import styles from './styles/styles.module.scss';

// https://www.figma.com/file/mh7ECfwLfHr8jTIaAYOS80/Trail-Cards?type=design&node-id=6-25282&mode=design&t=xNQhNCFkPZZZbgS6-4

type Props = {
  paginationElementClass: string;
};

// eslint-disable-next-line react/function-component-definition
export default function PaginationWrapper({ paginationElementClass }: Props) {
  return (
    <div className={classNames(styles.wrapper)}>
      <div className={classNames(paginationElementClass, styles.paginationElement)} />
    </div>
  );
}
