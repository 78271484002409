import type { WithContext, Thing } from 'schema-dts';

interface Props<T extends Thing> {
  data: T | WithContext<T>;
}

const JsonLdScript = <T extends Thing>({ data }: Props<T>) => {
  const linkedData: WithContext<T> = { '@context': 'https://schema.org', ...(data as any) };

  const stringifiedData = JSON.stringify(linkedData);

  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: stringifiedData }} />;
};

export default JsonLdScript;
