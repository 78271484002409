'use client';

import dynamic from 'next/dynamic';
import ReportLocation from '@alltrails/analytics/enums/ReportLocation';
import { useEffect, useState } from 'react';
import { useLightbox } from './LightboxContext';
import { PhotoHash } from '@/types/shared';
import { useSelector } from '@/utils/redux';

const DynamicLightbox = dynamic(() => import('@alltrails/lightbox').then(mod => mod.Lightbox), { ssr: false });

type Props = {
  photos: PhotoHash[];
};

const LightboxWrapper = ({ photos }: Props) => {
  const { isLightboxOpen, closeLightbox, lightboxStartingIndex } = useLightbox();
  const context = useSelector(state => state.context);
  const [formattedPhotos, setFormattedPhotos] = useState<any[] | null>(null);

  useEffect(() => {
    if (photos) {
      import('@alltrails/lightbox').then(module => {
        setFormattedPhotos(module.urlReformatPhotos(photos));
      });
    }
  }, [photos]);

  if (!isLightboxOpen || !formattedPhotos) {
    return null;
  }

  return (
    <DynamicLightbox
      photos={formattedPhotos}
      closeModal={closeLightbox}
      startingIndex={lightboxStartingIndex}
      context={context}
      languageRegionCode={context.languageRegionCode}
      analyticsReportLocation={ReportLocation.LocationWebPage}
    />
  );
};

export default LightboxWrapper;
