'use client';

import PageSectionType from '@alltrails/analytics/enums/PageSectionType';
import PageType from '@alltrails/analytics/enums/PageType';
import { useRef } from 'react';
import useLogWebSectionViewed from '@alltrails/layout/hooks/useLogWebSectionViewed';
import PageSection from '../layouts/PageSection';
import TopModuleSection from './TopModuleSection';
import { SubLocationDetailsApiResponse } from '@/types/subLocationListPages/shared';
import { LocationDetailsApiResponse } from '@/types/locationDetailsPages/shared';

type Props = {
  details: LocationDetailsApiResponse | SubLocationDetailsApiResponse;
  pageType?: PageType;
};

const TopModule = ({ details, pageType }: Props) => {
  const topModules = details?.rivers;
  const ref = useRef<HTMLDivElement>(null);
  useLogWebSectionViewed(ref, PageSectionType.TopModules, pageType, { threshold: 0.5 });

  if (!topModules || !topModules.length) {
    return null;
  }

  return (
    <>
      {topModules?.map?.((topModule, index) => {
        if (!topModule.items.length) {
          return null;
        }
        const isParkPage = (details as LocationDetailsApiResponse)?.type === 'park';
        const locationName = isParkPage ? (details.cityName ?? details.stateName ?? details.countryName) : undefined;

        return (
          <PageSection key={topModule.type} hideXOverflow>
            <TopModuleSection
              topModule={topModule}
              locationName={locationName}
              id={details.id}
              pageType={pageType}
              ref={index === 0 ? ref : undefined} // Pass a ref to the first element rather than using a wrapping div because that breaks the flex spacing from the page container
            />
          </PageSection>
        );
      })}
    </>
  );
};

export default TopModule;
