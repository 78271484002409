import Icon from '../components/Icon';

const PhotoSlash = Icon(
  'photo-slash',
  <>
    <path
      fillRule="evenodd"
      d="M2.53 1.47a.75.75 0 0 0-1.06 1.06l3.72 3.72H4A2.75 2.75 0 0 0 1.25 9v9A2.75 2.75 0 0 0 4 20.75h15.69l1.78 1.78a.75.75 0 1 0 1.06-1.06zm6.789 8.91-2.63-2.63H4A1.25 1.25 0 0 0 2.75 9v9A1.25 1.25 0 0 0 4 19.25h14.19l-3.568-3.568a3.75 3.75 0 1 1-5.303-5.303m1.06 1.06a2.25 2.25 0 1 0 3.182 3.182z"
      clipRule="evenodd"
    />
    <path d="M8.75 4a.75.75 0 0 1 .75-.75h5a.75.75 0 0 1 .576.27l2.275 2.73H20A2.75 2.75 0 0 1 22.75 9v7.5a.75.75 0 0 1-1.5 0V9A1.25 1.25 0 0 0 20 7.75h-3a.75.75 0 0 1-.576-.27l-2.275-2.73H9.5A.75.75 0 0 1 8.75 4" />
  </>
);

export default PhotoSlash;
