import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type BoundingBox from '../types/BoundingBox';
import type ExploreServiceFilters from '../types/ExploreServiceFilters';
import type ExploreServiceSort from '../types/ExploreServiceSort';
import type TrailCompletion from '../types/TrailCompletion';
import { defaultExploreServiceSort } from '../utils/constants';

export type ExploreServiceState = {
  boundingBox: BoundingBox | undefined;
  mapRotation: number;
  filters: ExploreServiceFilters;
  sort: ExploreServiceSort;
  trailCompletion: TrailCompletion[];
};

export const initialState: ExploreServiceState = {
  boundingBox: undefined,
  mapRotation: 0,
  filters: {},
  sort: defaultExploreServiceSort,
  trailCompletion: []
};

export const exploreServiceSlice = createSlice({
  name: 'exploreService',
  initialState,
  reducers: {
    updateBoundingBox: (state, action: PayloadAction<BoundingBox>) => {
      state.boundingBox = action.payload;
    },
    updateMapRotation: (state, action: PayloadAction<number>) => {
      state.mapRotation = action.payload;
    },
    updateFilters: (state, action: PayloadAction<ExploreServiceFilters>) => {
      state.filters = action.payload;
    },
    updateSort: (state, action: PayloadAction<ExploreServiceSort>) => {
      state.sort = action.payload;
    },
    updateTrailCompletion: (state, action: PayloadAction<TrailCompletion[]>) => {
      state.trailCompletion = action.payload;
    }
  }
});

export const { updateBoundingBox, updateMapRotation, updateFilters, updateSort, updateTrailCompletion } = exploreServiceSlice.actions;

export const exploreServiceReducer = exploreServiceSlice.reducer;
