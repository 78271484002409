import type { BreadcrumbList, ListItem } from 'schema-dts';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import { useLanguageRegionCode } from '@alltrails/language';
import { JsonLdScript } from '@alltrails/core';

type BreadcrumbsStructuredDataProps = {
  breadcrumbItems: {
    url?: string;
    name: string;
    noPrefetch?: boolean;
    onClick?: (index: number) => void;
  }[];
  includeUrlForLastItem?: boolean;
  baseUrl: string;
};

const BreadcrumbsStructuredData = ({ breadcrumbItems, includeUrlForLastItem, baseUrl }: BreadcrumbsStructuredDataProps) => {
  const languageRegionCode = useLanguageRegionCode();

  // Google requires at least two items in the breadcrumb list
  // https://developers.google.com/search/docs/appearance/structured-data/breadcrumb#structured-data-type-definitions
  if (breadcrumbItems.length < 2) {
    return null;
  }

  const itemListElement = breadcrumbItems.map((item, index, array) => {
    const excludeUrl = array.length - 1 === index && !includeUrlForLastItem;

    const listItem = {
      '@type': 'ListItem',
      position: String(index + 1),
      name: item.name
    } as ListItem;

    if (!excludeUrl && item.url) {
      listItem.item = baseUrl + wrapUrlSafe(item.url, languageRegionCode);
    }

    return listItem;
  });

  return (
    <JsonLdScript<BreadcrumbList>
      data={{
        '@type': 'BreadcrumbList',
        itemListElement
      }}
    />
  );
};

export default BreadcrumbsStructuredData;
