import BreadcrumbItem from './BreadcrumbItem';
import BreadcrumbsStructuredData from './BreadcrumbsStructuredData';

type BreadcrumbProps = {
  className?: string;
  breadcrumbItems: {
    url?: string;
    name: string;
    noPrefetch?: boolean;
    onClick?: (index: number) => void;
  }[];
  showCurrent?: boolean;
  includeUrlForLastItem?: boolean;
  testId: string;
  baseUrl: string;
};

export default function Breadcrumb({
  className,
  breadcrumbItems,
  showCurrent = true,
  includeUrlForLastItem = false,
  testId,
  baseUrl
}: BreadcrumbProps) {
  const displayBreadcrumbItems = showCurrent ? breadcrumbItems : breadcrumbItems.slice(0, breadcrumbItems.length - 1);

  return (
    <>
      <BreadcrumbsStructuredData breadcrumbItems={displayBreadcrumbItems} includeUrlForLastItem={includeUrlForLastItem} baseUrl={baseUrl} />
      <ul className={className} data-testid={testId}>
        {displayBreadcrumbItems.map((item, index, array) => {
          const jsDocIndex = index + 1; //  index is 1-indexed in jsdoc
          const { name, url } = item;
          const key = `${item.name}-${index}`;
          const isLast = array.length - 1 === index;
          const breadcrumbUrl = isLast && !includeUrlForLastItem ? undefined : url;

          return (
            <BreadcrumbItem
              url={breadcrumbUrl}
              name={name}
              index={jsDocIndex}
              isLast={isLast}
              key={key}
              onClick={item?.onClick}
            />
          );
        })}
      </ul>
    </>
  );
}
