import PageType from '@alltrails/analytics/enums/PageType';
import { LocationDetailsResponseType } from '@/types/locationDetailsPages/shared';

export const getAnalyticsPageType = (type?: LocationDetailsResponseType): PageType => {
  switch (type) {
    case 'poi':
      return PageType.PoiPage;
    case 'park':
      return PageType.ParkPage;
    default:
      return PageType.LocationPage;
  }
};
