import { useIntl, defineMessages, FormattedMessage } from '@alltrails/shared/react-intl';
import { Image } from '@alltrails/core';
import logPhotoClicked from '@alltrails/analytics/events/logPhotoClicked';
import { default as PageTypeAnalytics } from '@alltrails/analytics/enums/PageType';
import { useCallback, useState } from 'react';
import Button from '@alltrails/denali/components/Button';
import { BREAKPOINT_MD_SIZE } from '@alltrails/denali/tokens';
import styles from './PhotoHeader.module.scss';
import { PageType } from '@/types/shared';

const messages = defineMessages({
  title: {
    defaultMessage: 'Trails in {list} | AllTrails.com'
  }
});

type LocationDetails = {
  type: PageType;
  name: string;
  city?: string | null;
  state?: string | null;
  country?: string | null;
};

type Props = {
  photoCount: number;
  url: string;
  photoId: number;
  index: number;
  locationDetails: LocationDetails;
  locationSlug: string;
  hideShowMore?: boolean;
  openLightbox: (e: any, lightboxStartingIndex: number) => void;
  pageType?: PageTypeAnalytics;
};

const LocationPhotoCard = ({ photoCount, photoId, url, index, locationSlug, locationDetails, hideShowMore, openLightbox, pageType }: Props) => {
  const intl = useIntl();
  const { type, name, city, state, country } = locationDetails;
  const isLast = index === photoCount - 1 && photoCount > 1;
  const title = intl.formatMessage(messages.title, {
    list: [type === 'park' ? name : undefined, city, state, country].filter(_ => _).join(', ') + (photoCount > 1 ? ` ${photoId}` : '')
  });

  const handleOpenLightbox = useCallback(
    e => {
      e.stopPropagation();
      if (pageType) {
        logPhotoClicked({
          page_type: pageType,
          photo_id: String(photoId)
        });
      }
      openLightbox(e, index);
    },
    [openLightbox, index]
  );
  const [isLoaded, setIsLoaded] = useState(false);

  const imageButton = (
    <button aria-label={title} className={styles.carouselImage} onClick={handleOpenLightbox} type="button" key={photoId}>
      <div className={!!isLoaded ? styles.carouselImageOverlay : undefined} />
      <Image
        className={styles.locationPhoto}
        src={url}
        alt={title}
        fill
        sizes={`(max-width: ${BREAKPOINT_MD_SIZE}) 100vw, 960px`}
        loading={index === 0 ? 'eager' : 'lazy'}
        onLoad={() => setIsLoaded(true)}
        title={title}
        quality={90}
        isLcp={index === 0}
      />
    </button>
  );

  if (isLast && !hideShowMore && type !== 'poi') {
    return (
      <a href={`/${locationSlug}/photos`} key={photoId}>
        {imageButton}
        <div className={styles.showMore}>
          <Button variant="inverse" testId="show-more-photos" text={<FormattedMessage defaultMessage="All photos" />} />
        </div>
      </a>
    );
  }

  return imageButton;
};

export default LocationPhotoCard;
